import React from 'react'
import { Link } from 'react-router-dom';

const Projects = () => {
    return (
        <section className="md:py-[120px] py-20" id="projects">
        <div className="container">
            <div className="grid md:grid-cols-2 md:gap-[72px] items-center">
                <div>
                    <div className="max-w-[461px]">
                        <p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">Projects</p>
                        <h2 className="font-semibold mt-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">Our marvelous works</h2>
                    </div>
                    <div className="md:mt-12 mt-6">
                        <Link to="/projects" className="btn-green">View all project</Link>
                    </div>
                    <div className="mt-12 space-y-6 md:mt-14 md:space-y-[72px]">
                        <div className="bg-white rounded-2xl overflow-hidden">
                            <span>
                                <img src="/images/work/1.png" className="object-cover w-full h-full" alt="" />
                            </span>
                            <div className="p-8 md:max-w-[420px]">
                                <span className="hover:text-green font-semibold text-[22px] leading-8 duration-300">Powering Social Proof for Modern Business</span>
                                <p className="text-dark-semilight mt-4 font-medium text-xs leading-5 uppercase tracking-[0.86px]">branding</p>
                            </div>
                        </div>
                        <div className="bg-white rounded-2xl overflow-hidden">
                            <span>
                                <img src="/images/work/2.png" className="object-cover w-full h-full" alt="" />
                            </span>
                            <div className="p-8 md:max-w-[420px]">
                                <span className="hover:text-green font-semibold text-[22px] leading-8 duration-300">Bringing a modern mobile experience learning</span>
                                <p className="text-dark-semilight mt-4 font-medium text-xs leading-5 uppercase tracking-[0.86px]">Development</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 space-y-6 md:mt-[72px] md:space-y-[72px]">
                    <div className="bg-white rounded-2xl overflow-hidden">
                        <span>
                            <img src="/images/work/3.png" className="object-cover w-full h-full" alt="" />
                        </span>
                        <div className="p-8 md:max-w-[420px]">
                            <span className="hover:text-green font-semibold text-[22px] leading-8 duration-300">Art direction for leading publishing company</span>
                            <p className="text-dark-semilight mt-4 font-medium text-xs leading-5 uppercase tracking-[0.86px]">Development</p>
                        </div>
                    </div>
                    <div className="bg-white rounded-2xl overflow-hidden">
                        <span>
                            <img src="/images/work/4.png" className="object-cover w-full h-full" alt="" />
                        </span>
                        <div className="p-8 md:max-w-[420px]">
                            <span className="hover:text-green font-semibold text-[22px] leading-8 duration-300">Helping Australias oldest banking company</span>
                            <p className="text-dark-semilight mt-4 font-medium text-xs leading-5 uppercase tracking-[0.86px]">Design</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Projects