import React from 'react'
import Divider from '../../components/divider/Divider'
import PageHeader from '../../components/pageHeader/PageHeader'
import StartProject from '../../components/startProject/StartProject'
import { Link } from 'react-router-dom';

const Services = () => {
    return (
        <>
        <PageHeader currentPage='Services' currentPageUrl='/services'/>
        <Divider/>
        <section className="md:py-[120px] py-20">
            <div className="container">
                <div className="grid grid-cols-1">
                    <div className="relative">
                        <div className="hidden md:block border-light border-l absolute h-full left-1/2 transform -translate-x-1/2"></div>
                        <div className="relative">
                            <div className="flex flex-col md:flex-row items-center">
                                <div className="flex justify-end w-full mx-auto items-center">
                                    <div className="w-full md:w-1/2 md:block hidden">
                                        <img src="/images/instagram.svg" className="mx-auto" alt="" />
                                    </div>
                                    <div className="w-full md:w-1/2 md:pl-8 lg:pl-[110px]">
                                        <div className="bg-white p-8 rounded-2xl relative z-10">
                                            <div className="md:hidden block">
                                                <img src="/images/instagram.png" alt="" />
                                            </div>
                                            <h5 className="font-semibold text-[22px] leading-[33px] mt-7 md:mt-0">Branding</h5>
                                            <p className="text-[15px] leading-7 text-dark-light mt-4">
                                            We create unique name, design, symbol, or any other feature that identifies and 
                                            distinguishes a product, service, or organization from others in the market.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute left-1/2 -translate-y-1/2 -translate-x-1/2 top-1/2 md:block hidden">
                                    <div className="bg-green w-4 h-4 rounded-full"></div>
                                    <span className="h-px w-[93px] absolute inline-block bg-light -z-20 top-1/2 -translate-y-1/2"></span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 md:mt-[120px] relative">
                            <div className="flex flex-col md:flex-row items-center">
                                <div className="flex justify-start w-full mx-auto items-center">
                                    <div className="w-full md:w-1/2 md:pr-8 lg:pr-[110px]">
                                        <div className="bg-white p-8 rounded-2xl relative z-10">
                                            <div className="md:hidden block">
                                                <img src="/images/web-development.png" alt="" />
                                            </div>
                                            <h5 className="font-semibold text-[22px] leading-[33px] mt-7 md:mt-0">Development</h5>
                                            <p className="text-[15px] leading-7 text-dark-light mt-4">
                                            Creating and managing content, optimizing websites for search engines, and ensuring that websites are
                                            accessible and meet industry standards for web development.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2 md:pr-8 lg:pr-[110px] md:block hidden">
                                        <img src="/images/web-development.svg" className="mx-auto" alt="" />
                                    </div>
                                </div>
                                <div className="absolute left-1/2 -translate-y-1/2 -translate-x-1/2 top-1/2 md:block hidden">
                                    <div className="bg-green w-4 h-4 rounded-full"></div>
                                    <span className="h-px w-[93px] absolute inline-block bg-light right-0 -z-20 top-1/2 -translate-y-1/2"></span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 md:mt-[120px] relative">
                            <div className="flex flex-col md:flex-row items-center">
                                <div className="flex justify-end w-full mx-auto items-center">
                                    <div className="w-full md:w-1/2 md:block hidden">
                                        <img src="/images/graphic-designer.svg" className="mx-auto" alt="" />
                                    </div>
                                    <div className="w-full md:w-1/2 md:pl-8 lg:pl-[110px]">
                                        <div className="bg-white p-8 rounded-2xl relative z-10">
                                            <div className="md:hidden block">
                                                <img src="/images/graphic-designer.png" alt="" />
                                            </div>
                                            <h5 className="font-semibold text-[22px] leading-[33px] mt-7 md:mt-0">Design</h5>
                                            <p className="text-[15px] leading-7 text-dark-light mt-4">
                                            Designing the user interface (UI) and user experience (UX) of digital products such as websites, mobile apps, and software. 
                                            We create digital products that are intuitive.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute left-1/2 -translate-y-1/2 -translate-x-1/2 top-1/2 md:block hidden">
                                    <div className="bg-green w-4 h-4 rounded-full"></div>
                                    <span className="h-px w-[93px] absolute inline-block bg-light -z-20 top-1/2 -translate-y-1/2"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 text-center">
                        <Link to="/projects" className="btn-green">View all project</Link>
                    </div>
                </div>
            </div>
        </section>
        <Divider/>
        <StartProject/>
        </>
    )
}

export default Services