import { useRef, useState } from "react";
import emailjs from "emailjs-com";
import PageHeader from "../../components/pageHeader/PageHeader";
import Divider from "../../components/divider/Divider";
import StartProject from "../../components/startProject/StartProject";

const Contact = () => {
  const formRef = useRef();
  const [done, setDone] = useState(false);
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);
    emailjs
      .sendForm(
        "aprizal",
        "template_cu1qijy",
        formRef.current,
        "8uwstz9Uw_uh5Cb8J"
      )
      .then(
        () => {
          setDone(true);
          setProcessing(false);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        },
        (error) => {
          setProcessing(false);
          console.log(error);
          setError(error.text);
        }
      );
  };

  return (
    <>
      <PageHeader currentPage="Contact" currentPageUrl="/contact-us" />
      <Divider />
      <section className="md:py-[120px] py-20">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
            <div>
              <img
                src="assets/images/map.png"
                className="rounded-full"
                alt=""
              />
            </div>
            <div>
              <div className="lg:max-w-[461px] lg:ml-auto">
                <p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">
                  Find us
                </p>
                <h2 className="font-semibold mt-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">
                  We'd love to hear from you
                </h2>
                <p className="md:mt-8 mt-4 text-[#7B7B7B] text-[15px] leading-7 max-w-[200px]">
                  16192 Coastal Highway, Lewes, Delaware 19957, County of Sussex
                </p>
                <p className="mt-6 text-[#7B7B7B] text-[15px] leading-7">
                  +1 4096 552 653
                </p>
                <p className="text-[15px] leading-7 text-[#7B7B7B]">
                  info@aprizal.com
                </p>
                <p
                  className="mt-6 text-[#7B7B7B] text-[15px] leading-7"
                  style={{ display: "flex" }}
                >
                  <a
                    style={{ paddingRight: "5px" }}
                    href="https://www.facebook.com/ApriZaldotCom/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>

                  </a>
                  <a
                    style={{ paddingRight: "5px", marginTop: "-3px" }}
                    href="https://www.instagram.com/aprizalweb"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                  <a
                    style={{ paddingRight: "5px", marginTop: "-3px" }}
                    href="https://twitter.com/apriZaldotCOm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a
                    style={{ paddingRight: "5px", marginTop: "-3px" }}
                    href="https://www.linkedin.com/in/apri-zal-1a372828a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Divider />
      <section className="md:py-[120px] py-20">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="max-w-[500px] text-center mx-auto">
              <p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">
                Stay in Touch
              </p>
              <h2 className="font-semibold mt-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">
                Send us a message
              </h2>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 mt-12">
            <form
              className="max-w-[752px] w-full mx-auto"
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter your name"
                    name="user_name"
                  />
                </div>
                <div>
                  <input
                    type="email"
                    className="form-input"
                    placeholder="Enter your email"
                    name="user_email"
                  />
                </div>
                <div className="sm:col-span-2">
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Type your subject"
                    name="user_subject"
                  />
                </div>
                <div className="sm:col-span-2">
                  <textarea
                    rows="5"
                    className="form-input"
                    placeholder="Write your message"
                    spellcheck="false"
                    name="message"
                  ></textarea>
                </div>
                <div className="sm:col-span-2">
                  {/* <input type="button"  value={processing ? 'Sending...' : 'Submit Message'} /> */}
                  <button className="btn-green w-full">
                    {processing ? "SENDING..." : "SEND MESSAGE"}
                  </button>
                </div>
                {done && (
                  <div className="sm:col-span-2">
                    Message sent successfully. Please wait for my reply. Thank
                    you...
                  </div>
                )}
                {done && <div className="sm:col-span-2">{error}</div>}
              </div>
            </form>
          </div>
        </div>
      </section>
      <Divider />
      <StartProject />
    </>
  );
};

export default Contact;
