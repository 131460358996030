import React from 'react'
import Divider from '../../components/divider/Divider'
import PageHeader from '../../components/pageHeader/PageHeader'
import StartProject from '../../components/startProject/StartProject'

const TermsCondtions = () => {
    return (
        <>
        <PageHeader currentPage='Terms & Conditions' currentPageUrl='/terms-conditions'/>
        <Divider/>
        <section className="md:py-[120px] py-20">
            <div className="container">
                <div className="grid grid-cols-1">
                    <div className="mx-auto w-full max-w-[752px]">
                        <div className="accordion-container faq space-y-4">
                            <div className="ac mt-0 bg-white rounded-lg border-none">
                                <h2 className="ac-header">
                                    <button type="button" className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden">
                                        <span className="text-lg font-semibold">Introduction</span>
                                        <div className="leading-[22px] faq-arrow">
                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z"
                                                    fill="#3EC37D"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </h2>
                                <div className="ac-panel">
                                    <p className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
                                    Welcome to Aprizal Web Development and Design Agency (“Aprizal”). These Terms and Conditions (“Terms”) 
                                    apply to our web development and design services and any other services we provide to you (“Services”). 
                                    These Terms will govern your contractual relationship with us. 
                                    They form a legal agreement between you and Aprizal that will continue to apply to you until our Services are completed. 
                                    </p>
                                </div>
                            </div>
                            <div className="ac mt-0 bg-white rounded-lg border-none">
                                <h2 className="ac-header">
                                    <button type="button" className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden">
                                        <span className="text-lg font-semibold">Our Services</span>
                                        <div className="leading-[22px] faq-arrow">
                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z"
                                                    fill="#3EC37D"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </h2>
                                <div className="ac-panel">
                                    <p className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
                                    We will provide our Services to you in accordance with the terms and conditions set out in our proposal or contract 
                                    with you. You acknowledge that any changes to these Terms or our proposal must be agreed to by both parties in writing
                                    </p>
                                </div>
                            </div>
                            <div className="ac mt-0 bg-white rounded-lg border-none">
                                <h2 className="ac-header">
                                    <button type="button" className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden">
                                        <span className="text-lg font-semibold">Your Obligations</span>
                                        <div className="leading-[22px] faq-arrow">
                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z"
                                                    fill="#3EC37D"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </h2>
                                <div className="ac-panel">
                                    <p className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
                                    You agree to cooperate with us and provide us with all necessary information relevant to the Services. 
                                    You must ensure that any materials, content or information that you provide to us for use on your website is 
                                    accurate, complete and not in breach of any third party rights
                                    </p>
                                </div>
                            </div>
                            <div className="ac mt-0 bg-white rounded-lg border-none">
                                <h2 className="ac-header">
                                    <button type="button" className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden">
                                        <span className="text-lg font-semibold"> Changes to the Services</span>
                                        <div className="leading-[22px] faq-arrow">
                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z"
                                                    fill="#3EC37D"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </h2>
                                <div className="ac-panel">
                                    <p className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
                                    We may make changes to the Services provided that they do not substantially affect the quality of the Services. 
                                    If we need to make such changes, we will notify you as soon as possible and seek your agreement to the changes. 
                                    </p>
                                </div>
                            </div>
                            <div className="ac mt-0 bg-white rounded-lg border-none">
                                <h2 className="ac-header">
                                    <button type="button" className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden">
                                        <span className="text-lg font-semibold">Payment Terms</span>
                                        <div className="leading-[22px] faq-arrow">
                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z"
                                                    fill="#3EC37D"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </h2>
                                <div className="ac-panel">
                                    <p className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
                                    You agree to pay the fees and charges set out in our proposal or contract with you. 
                                    If payment is not made in accordance with the payment terms set out in our proposal or 
                                    contract there will be an interest charge at a rate of 2% above the Bank of England's base rate from time to time. 
                                    </p>
                                </div>
                            </div>
                            <div className="ac mt-0 bg-white rounded-lg border-none">
                                <h2 className="ac-header">
                                    <button type="button" className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden">
                                        <span className="text-lg font-semibold">Intellectual Property Rights</span>
                                        <div className="leading-[22px] faq-arrow">
                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z"
                                                    fill="#3EC37D"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </h2>
                                <div className="ac-panel">
                                    <p className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
                                    You agree to pay the fees and charges set out in our proposal or contract with you. 
                                    If payment is not made in accordance with the payment terms set out in our proposal or 
                                    contract there will be an interest charge at a rate of 2% above the Bank of England's base rate from time to time. 
                                    </p>
                                </div>
                            </div>
                            <div className="ac mt-0 bg-white rounded-lg border-none">
                                <h2 className="ac-header">
                                    <button type="button" className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden">
                                        <span className="text-lg font-semibold">Liability and Indemnification</span>
                                        <div className="leading-[22px] faq-arrow">
                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z"
                                                    fill="#3EC37D"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </h2>
                                <div className="ac-panel">
                                    <p className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
                                    We will not be liable for any damages, including indirect, incidental, special, punitive, or consequential damages, 
                                    arising out of or in connection with the Services, or the content, products or services made available through your website. 
                                    You will indemnify, defend and hold us harmless from and against any claims, losses, damages or expenses 
                                    (including reasonable attorneys’ fees) arising out of or in connection with your breach of these Terms, or your use of our Services.  
                                    </p>
                                </div>
                            </div>
                            <div className="ac mt-0 bg-white rounded-lg border-none">
                                <h2 className="ac-header">
                                    <button type="button" className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden">
                                        <span className="text-lg font-semibold">Liability and Indemnification</span>
                                        <div className="leading-[22px] faq-arrow">
                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z"
                                                    fill="#3EC37D"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </h2>
                                <div className="ac-panel">
                                    <p className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
                                     We will treat all information provided by you as confidential and will not disclose it to any third party without your consent, unless required by law. 
                                        Dispute Resolution and Governing Law These Terms will be governed by and construed in accordance with the laws of the state of Delaware, USA. Any dispute, controversy or claim arising out of or in connection with these Terms, or the breach, termination or invalidity thereof, shall be resolved by the courts of the state of Delaware, USA.  
                                    </p>
                                </div>
                            </div>
                            <div className="ac mt-0 bg-white rounded-lg border-none">
                                <h2 className="ac-header">
                                    <button type="button" className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden">
                                        <span className="text-lg font-semibold">Termination</span>
                                        <div className="leading-[22px] faq-arrow">
                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z"
                                                    fill="#3EC37D"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </h2>
                                <div className="ac-panel">
                                    <p className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
                                     Either party may terminate these Terms if the other party materially breaches these Terms and fails to 
                                     remedy the breach within thirty (30) days of receiving written notice of the breach.
                                     </p>
                                </div>
                            </div>
                            <div className="ac mt-0 bg-white rounded-lg border-none">
                                <h2 className="ac-header">
                                    <button type="button" className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden">
                                        <span className="text-lg font-semibold">Miscellaneous</span>
                                        <div className="leading-[22px] faq-arrow">
                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z"
                                                    fill="#3EC37D"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </h2>
                                <div className="ac-panel">
                                    <p className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
                                    These Terms create no partnership, joint venture, agency, fiduciary or similar relationship between you and us. 
                                    If any provision of these Terms is deemed invalid or unenforceable, that provision will be stricken and the remaining provisions 
                                    will remain in full force and effect.                                     
                                    </p>
                                </div>
                            </div>
                            <div className="ac mt-0 bg-white rounded-lg border-none">
                                <h2 className="ac-header">
                                    <button type="button" className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden">
                                        <span className="text-lg font-semibold">Contact Us</span>
                                        <div className="leading-[22px] faq-arrow">
                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z"
                                                    fill="#3EC37D"
                                                />
                                            </svg>
                                        </div>
                                    </button>
                                </h2>
                                <div className="ac-panel">
                                    <p className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
                                    If you have any questions about these Terms, our Services or want to request 
                                    further information, please contact us at info@aprizal.com.                                     
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Divider/>
        <StartProject/>
        </>
    )
}

export default TermsCondtions