import React from 'react'
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from './pages/Home';
import Contact from './pages/contact/Contact';
import Project from './pages/projects/Projects';
import Services from './pages/services/Services';
import About from './pages/about/About';
import TermsCondition from './pages/termsConditions/TermsCondtions';
import NotFound from './pages/notFound/NotFound';


const App = () => {
 
  return (
    <Router>
      <Header/>
      <Routes >
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/projects" element={<Project/>} />
        <Route exact path="/services" element={<Services/>} />
        <Route exact path="/about-us" element={<About/>} />
        <Route exact path="/contact-us" element={<Contact/>} />
        <Route exact path="/terms-conditions" element={<TermsCondition/>} />
        <Route path='*' element={<NotFound />}/>
      </Routes>
      <Footer/>
    </Router>
  );
};

export default App;
