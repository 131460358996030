import React from 'react'
import Divider from '../../components/divider/Divider';
import StartProject from '../../components/startProject/StartProject';
import PageHeader from './../../components/pageHeader/PageHeader';

const About = () => {
  return (
   <>
    <PageHeader currentPage='About' currentPageUrl='/about-us'/>
    <Divider/>
    <section class="md:py-[120px] py-20">
            <div class="container">
                <div class="grid grid-cols-1">
                    <div class="max-w-[946px] text-center mx-auto">
                        <p class="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">Overview</p>
                        <h5 class="font-semibold mt-4 leading-snug">
                        Aprizal is a web design, UI/UX design, graphics, and mobile development Agency.
                        We work with startups and established businesses to create beautiful, user-friendly websites and mobile apps.
                        </h5>
                        <h5 class="font-semibold mt-4 leading-snug">
                        We believe that good design is essential to the success of any online or mobile business. 
                        That's why we take a user-centered approach to our work, 
                        making sure that every site or app we build is easy to use and visually appealing.
                        </h5>
                        <h5 class="font-semibold mt-4 leading-snug">
                        Our team has a wide range of skills and experience, which means that we're able to 
                        take on projects of all sizes and complexity. Whether you need a simple one-page website or 
                        a complex e-commerce platform, we're confident that we can deliver results that exceed your expectations.
                        Reach out to us by sending a mail : info@aprizal.com
                        </h5>
                    </div>
                </div>
                <div class="grid grid-cols-1 mt-12">
                    <div class="md:mt-[120px] mt-12 relative">
                        <img src="/images/about-img.jpg" class="rounded-2xl" alt="" />
                    </div>
                </div>
            </div>
        </section>
    <Divider/>
    <section class="md:py-[120px] py-20">
            <div class="container">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
                    <div>
                        <div class="lg:max-w-[461px]">
                            <p class="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">Skills</p>
                            <h2 class="font-semibold mt-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">Our super skills for your project</h2>
                            <div class="mt-12 space-y-6">
                                <div>
                                    <p class="text-[15px] leading-[22px] font-medium">Branding</p>
                                    <div class="relative h-1 w-full mt-4 rounded-2xl">
                                        <div class="bg-green absolute block top-0 left-0 h-full w-[80%] rounded-2xl">
                                            <span class="text-green absolute -right-4 bottom-full mb-4 text-[15px] leading-[22px] font-medium">
                                                80%
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p class="text-[15px] leading-[22px] font-medium">Development</p>
                                    <div class="relative h-1 w-full mt-4 rounded-2xl">
                                        <div class="bg-green absolute block top-0 left-0 h-full w-[94%] rounded-2xl">
                                            <span class="text-green absolute -right-4 bottom-full mb-4 text-[15px] leading-[22px] font-medium">
                                                94%
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p class="text-[15px] leading-[22px] font-medium">Design</p>
                                    <div class="relative h-1 w-full mt-4 rounded-2xl">
                                        <div class="bg-green absolute block top-0 left-0 h-full w-[76%] rounded-2xl">
                                            <span class="text-green absolute -right-4 bottom-full mb-4 text-[15px] leading-[22px] font-medium">
                                                76%
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src="/images/getin.png" class="rounded-full" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <Divider/>
    <StartProject/>
   </>  
  )
}

export default About
