import React from 'react'
import Hero from "../components/hero/Hero";
import Services from "../components/services/Services";
import Divider from "../components/divider/Divider";
import Projects from "../components/projects/Projects";
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <>
            <Hero/>
            <Services />
            <Divider />
            {/* Home About Section */}
            <section className="md:py-[120px] py-20">
                <div className="container">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
                        <div>
                            <img src="/images/about.png" className="rounded-full" alt="about us" />
                        </div>
                        <div>
                            <div className="lg:max-w-[461px] lg:ml-auto">
                                <p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">About us</p>
                                <h2 className="font-semibold mt-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">Best agency for your project</h2>
                                <p className="mt-12 text-[15px] leading-7 text-dark-light">
                                    Our work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work. And the only way to do great work...
                                </p>
                                <div className="mt-14">
                                    <Link to="/about-us" className="btn-green">Learn more</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Divider />
            <Projects/>
            <Divider/>
            <section className="md:py-[120px] py-20">
                <div className="container">
                    <div className="grid grid-cols-1">
                        <div className="max-w-[780px] text-center mx-auto">
                            <h2 className="text-4xl leading-snug md:text-6xl md:leading-snug lg:text-[70px] lg:leading-[90px] font-semibold">Build your project with Aprizal</h2>
                            <p className="mt-8 text-lg leading-[30px] text-dark-light">We design and build beautiful websites, apps and branding</p>
                            <div className="mt-12">
                                <Link to="/contact-us" className="btn-green">Start a project</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>        
            <Divider/>
        </>
    )
}

export default Home