import React from 'react'
import { Link } from 'react-router-dom';

const Services = () => {
    return (
        <section className="md:py-[120px] py-20">
        <div className="container">
            <div className="grid grid-cols-1 md:grid-cols-2 items-end gap-6">
                <div className="max-w-[461px]">
                    <p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">What we do</p>
                    <h2 className="font-semibold mt-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">Delivering quality products</h2>
                </div>
                <div className="flex md:flex-row flex-col md:items-center gap-2 md:gap-16 justify-end max-w-sm md:ml-auto">
                    <div className="flex items-center text-left gap-4">
                        <h2 className="text-[56px] leading-[72px] font-semibold text-green" data-vanilla-counter data-start-at="0" data-end-at="13" data-time="3000" data-delay="0" data-format="{}"></h2>
                        <p className="md:text-[15px] md:leading-[22px] text-lg">Years in business</p>
                    </div>
                    <div className="flex items-center text-left gap-4">
                        <h2 className="text-[56px] leading-[72px] font-semibold text-green" data-vanilla-counter data-start-at="0" data-end-at="28" data-time="3000" data-delay="0" data-format="{}"></h2>
                        <p className="md:text-[15px] md:leading-[22px] text-lg">Designers & developers</p>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-12">
                <div className="p-8 pt-16 bg-white hover:shadow-custom duration-300 rounded-2xl">
                    <img src="/images/instagram.png" alt="" />
                    <h4 className="text-[22px] leading-8 font-semibold mt-16">Branding</h4>
                    <p className="mt-4 text-[15px] leading-7 text-dark-light">
                    We create unique name, design, symbol, or any other feature that identifies and 
                    distinguishes a product, service, or organization from others in the market.
                    </p>
                    <Link to="/services" className="w-8 h-8 mt-8 bg-green flex items-center justify-center rounded-full">
                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.293214 9.70697C0.105743 9.51944 0.00042731 9.26513 0.000427333 8.99997C0.000427357 8.7348 0.105743 8.4805 0.293214 8.29297L3.58621 4.99997L0.293215 1.70697C0.111057 1.51837 0.0102623 1.26576 0.0125407 1.00357C0.014819 0.741372 0.119988 0.490558 0.305396 0.30515C0.490804 0.119743 0.741617 0.0145736 1.00381 0.0122953C1.26601 0.010017 1.51861 0.110811 1.70721 0.292969L5.70721 4.29297C5.89468 4.4805 6 4.73481 6 4.99997C6 5.26513 5.89468 5.51944 5.70721 5.70697L1.70721 9.70697C1.51969 9.89444 1.26538 9.99976 1.00021 9.99976C0.735049 9.99976 0.480742 9.89444 0.293214 9.70697Z"
                                fill="#FAFAFA"
                            />
                        </svg>
                    </Link>
                </div>
                <div className="p-8 pt-16 bg-white hover:shadow-custom duration-300 rounded-2xl">
                    <img src="/images/web-development.png" alt="" />
                    <h4 className="text-[22px] leading-8 font-semibold mt-16">Development</h4>
                    <p className="mt-4 text-[15px] leading-7 text-dark-light">
                    Creating and managing content, optimizing websites for search engines, and ensuring that websites are
                     accessible and meet industry standards for web development.                    </p>
                    <Link to="/services" className="w-8 h-8 mt-8 bg-green flex items-center justify-center rounded-full">
                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.293214 9.70697C0.105743 9.51944 0.00042731 9.26513 0.000427333 8.99997C0.000427357 8.7348 0.105743 8.4805 0.293214 8.29297L3.58621 4.99997L0.293215 1.70697C0.111057 1.51837 0.0102623 1.26576 0.0125407 1.00357C0.014819 0.741372 0.119988 0.490558 0.305396 0.30515C0.490804 0.119743 0.741617 0.0145736 1.00381 0.0122953C1.26601 0.010017 1.51861 0.110811 1.70721 0.292969L5.70721 4.29297C5.89468 4.4805 6 4.73481 6 4.99997C6 5.26513 5.89468 5.51944 5.70721 5.70697L1.70721 9.70697C1.51969 9.89444 1.26538 9.99976 1.00021 9.99976C0.735049 9.99976 0.480742 9.89444 0.293214 9.70697Z"
                                fill="#FAFAFA"
                            />
                        </svg>
                    </Link>
                </div>
                <div className="p-8 pt-16 bg-white hover:shadow-custom duration-300 rounded-2xl">
                    <img src="/images/graphic-designer.png" alt="" />
                    <h4 className="text-[22px] leading-8 font-semibold mt-16">Design</h4>
                    <p className="mt-4 text-[15px] leading-7 text-dark-light">
                    Designing the user interface (UI) and user experience (UX) of digital products such as websites, mobile apps, and software. 
                    We create digital products that are intuitive.</p>
                    <Link to="/services" className="w-8 h-8 mt-8 bg-green flex items-center justify-center rounded-full">
                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.293214 9.70697C0.105743 9.51944 0.00042731 9.26513 0.000427333 8.99997C0.000427357 8.7348 0.105743 8.4805 0.293214 8.29297L3.58621 4.99997L0.293215 1.70697C0.111057 1.51837 0.0102623 1.26576 0.0125407 1.00357C0.014819 0.741372 0.119988 0.490558 0.305396 0.30515C0.490804 0.119743 0.741617 0.0145736 1.00381 0.0122953C1.26601 0.010017 1.51861 0.110811 1.70721 0.292969L5.70721 4.29297C5.89468 4.4805 6 4.73481 6 4.99997C6 5.26513 5.89468 5.51944 5.70721 5.70697L1.70721 9.70697C1.51969 9.89444 1.26538 9.99976 1.00021 9.99976C0.735049 9.99976 0.480742 9.89444 0.293214 9.70697Z"
                                fill="#FAFAFA"
                            />
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Services