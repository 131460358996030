import React from 'react'

const Divider = () => {
    return (
        <div className="container">
            <div className="bg-light h-px w-full block"></div>
        </div>
    )
}

export default Divider