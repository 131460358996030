import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

const useMenuActive = () => {
  useEffect(() => {
    const linkObj = document.getElementById("navbarNav").getElementsByTagName("a");
    const url = new URL(window.location.href);
    for (let i = 0; i < linkObj.length; i++) {
      if ((document.location.href.indexOf(linkObj[i].href) >= 0 && linkObj[i].href !== url.protocol + "//" + url.hostname + "/") || document.location.href === linkObj[i].href) {
        linkObj[i].classList.add("sub-active");
      }
    }
  }, []);
}

const useStickyAdd = () => {
  useEffect(() => {
    const navbar = document.querySelector("nav");
    const handleScroll = () => {
      if (window.pageYOffset > 80) {
        navbar.classList.add("stickyadd");
      } else {
        navbar.classList.remove("stickyadd");
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
}

const useMenuCollapsed = () => {
  useEffect(() => {
    const btn = document.querySelector("button.mobile-menu-button");
    const menu = document.querySelector(".mobile-menu");
    const handleButtonClick = () => {
      menu.classList.toggle("hidden");
    };
    btn.addEventListener("click", handleButtonClick);
    return () => btn.removeEventListener("click", handleButtonClick);
  }, []);
}

const Header = () => {
    useMenuActive();
    useStickyAdd();
    useMenuCollapsed();
    return (
        <header className="main-menu">
        <nav className="fixed top-0 w-full z-50 mobile-menu-bg">
            <div className="container">
                <div className="lg:flex items-center justify-between gap-6">
                    <div className="flex items-center justify-between">
                        <Link to="/">
                            <img src="/images/logo-dark.svg" className="" alt="logo" />
                        </Link>
                        <button className="text-end mobile-menu-button lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="w-8 h-8">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                    </div>
                    <div id="navbarNav" className="mobile-menu hidden lg:block">
                        <ul className="lg:space-x-8 space-y-4 lg:space-y-0 lg:flex items-center mt-6 lg:mt-0 max-[1024px]:max-h-80 max-[1024px]:overflow-scroll">
                            <li className="nav-active">
                                <Link to="/" className="uppercase text-sm font-medium lg:py-9 tracking-[0.86px] text-dark-semilight relative hover:text-dark duration-300">
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to="/projects" className="uppercase text-sm font-medium lg:py-9 tracking-[0.86px] text-dark-semilight relative hover:text-dark duration-300">
                                    Projects
                                </Link>
                            </li>
                            <li>
                                <Link to="/services" className="uppercase text-sm font-medium lg:py-9 tracking-[0.86px] text-dark-semilight relative hover:text-dark duration-300">
                                    Services
                                </Link>
                            </li>
                            <li>
                                <Link to="/about-us" className="uppercase text-sm font-medium lg:py-9 tracking-[0.86px] text-dark-semilight relative hover:text-dark duration-300">
                                    About
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact-us" className="uppercase text-sm font-medium lg:py-9 tracking-[0.86px] text-dark-semilight relative hover:text-dark duration-300">
                                    Contact
                                </Link>
                            </li>
                            <Link to="/contact-us" className="btn-blue navbar-btn lg:!ml-16">Start a project</Link>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </header>
    )
}

export default Header;
