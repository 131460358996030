import React from 'react'
import Divider from '../../components/divider/Divider'
import PageHeader from '../../components/pageHeader/PageHeader'
import StartProject from '../../components/startProject/StartProject'

const Project = () => {
    return (
        <>
            <PageHeader currentPage='Projects' currentPageUrl='/projects' />
            <Divider/>
            <section className="md:py-[120px] py-20">
                <div className="container">
                    <div className="grid md:grid-cols-2 md:gap-[72px]">
                        <div>
                            <div className="space-y-6 md:space-y-[72px]">
                                <div className="bg-white rounded-2xl overflow-hidden">
                                    <span>
                                        <img src="/images/work/1.png" className="object-cover w-full h-full" alt="" />
                                    </span>
                                    <div className="p-8 md:max-w-[420px]">
                                        <span className="hover:text-green font-semibold text-[22px] leading-8 duration-300">Powering Social Proof for Modern Business</span>
                                        <p className="text-dark-semilight mt-4 font-medium text-xs leading-5 uppercase tracking-[0.86px]">branding</p>
                                    </div>
                                </div>
                                <div className="bg-white rounded-2xl overflow-hidden">
                                    <span>
                                        <img src="/images/work/2.png" className="object-cover w-full h-full" alt="" />
                                    </span>
                                    <div className="p-8 md:max-w-[420px]">
                                        <span className="hover:text-green font-semibold text-[22px] leading-8 duration-300">Bringing a modern mobile experience learning</span>
                                        <p className="text-dark-semilight mt-4 font-medium text-xs leading-5 uppercase tracking-[0.86px]">Development</p>
                                    </div>
                                </div>
                                <div className="bg-white rounded-2xl overflow-hidden">
                                    <span>
                                        <img src="/images/work/5.png" className="object-cover w-full h-full" alt="" />
                                    </span>
                                    <div className="p-8 md:max-w-[420px]">
                                        <span className="hover:text-green font-semibold text-[22px] leading-8 duration-300">Design for one of the major figures of fashion</span>
                                        <p className="text-dark-semilight mt-4 font-medium text-xs leading-5 uppercase tracking-[0.86px]">Design</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="space-y-6 mt-6 md:mt-32 md:space-y-[72px]">
                            <div className="bg-white rounded-2xl overflow-hidden">
                                <span>
                                    <img src="/images/work/3.png" className="object-cover w-full h-full" alt="" />
                                </span>
                                <div className="p-8 md:max-w-[420px]">
                                    <span className="hover:text-green font-semibold text-[22px] leading-8 duration-300">Art direction for leading publishing company</span>
                                    <p className="text-dark-semilight mt-4 font-medium text-xs leading-5 uppercase tracking-[0.86px]">Development</p>
                                </div>
                            </div>
                            <div className="bg-white rounded-2xl overflow-hidden">
                                <span>
                                    <img src="/images/work/4.png" className="object-cover w-full h-full" alt="" />
                                </span>
                                <div className="p-8 md:max-w-[420px]">
                                    <span className="hover:text-green font-semibold text-[22px] leading-8 duration-300">Helping Australias oldest banking company</span>
                                    <p className="text-dark-semilight mt-4 font-medium text-xs leading-5 uppercase tracking-[0.86px]">Design</p>
                                </div>
                            </div>
                            <div className="bg-white rounded-2xl overflow-hidden">
                                <span>
                                    <img src="/images/work/6.png" className="object-cover w-full h-full" alt="" />
                                </span>
                                <div className="p-8 md:max-w-[420px]">
                                    <span className="hover:text-green font-semibold text-[22px] leading-8 duration-300">Improving a business through design & strategy</span>
                                    <p className="text-dark-semilight mt-4 font-medium text-xs leading-5 uppercase tracking-[0.86px]">Branding</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Divider/>
            <StartProject/>
        </>
    )
}

export default Project