import React from 'react'

const Hero = () => {
    return (
      <section className="bg-top pt-36 md:pt-[240px]" style={{backgroundImage: 'url(/images/home-bg.jpg)'}}>
      <div className="container">
          <div className="grid grid-cols-1">
              <div className="text-center">
                  <h1 className="font-semibold text-4xl leading-snug md:text-6xl md:leading-snug lg:text-[70px] lg:leading-[90px] max-w-[800px] mx-auto">We make remarkable digital products</h1>
                  <p className="md:mt-8 mt-5 text-dark-light text-base md:text-lg leading-[30px]">We design and build beautiful websites, apps and branding</p>
                  <div className="md:mt-12 mt-7">
                      <a href="#projects" className="btn-green">View our work</a>
                  </div>
              </div>
          </div>
      </div>
      <div className="mt-14 max-w-[1600px] mx-auto">
          <img src="/images/hero-img.png" alt="" />
      </div>
    </section>
    )
}

export default Hero;