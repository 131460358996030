import React from 'react'
import Divider from '../../components/divider/Divider';
import PageHeader from './../../components/pageHeader/PageHeader';

const NotFound = () => {
  return (
   <>
    <PageHeader currentPage='Page Not Found' currentPageUrl='*'/>
    <Divider/>
   </>  
  )
}

export default NotFound
