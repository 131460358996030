import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="bg-dark">
            <div className="container">
                <div className="grid grid-cols-1 md:grid-cols-5 gap-10 lg:gap-24 md:py-[120px] py-20">
                    <div className="md:col-span-2 md:border-r border-[#3D3D3D]">
                        <div className="max-w-xs">
                            <img src="/images/logo-light.svg" alt="" />
                            <p className="mt-8 text-[15px] leading-7 text-[#7B7B7B]">With more than 20 years of experience we can deliver the best product and web design</p>
                        </div>
                    </div>
                    <div className="md:col-span-3">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            <div className="text-white">
                                <p className="font-semibold text-lg leading-7 text-white">Navigation</p>
                                <ul className="md:mt-8 mt-4 space-y-4">
                                    <li>
                                        <Link to="/" className="text-[#7B7B7B] hover:text-[#FAFAFA] text-[15px] leading-[22px] duration-300">
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/projects" className="text-[#7B7B7B] hover:text-[#FAFAFA] text-[15px] leading-[22px] duration-300">
                                            Projects
                                        </Link>
                                    </li>
                                    
                                    <li>
                                        <Link to="/contact-us" className="text-[#7B7B7B] hover:text-[#FAFAFA] text-[15px] leading-[22px] duration-300">
                                            Contact
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="text-white">
                                <p className="font-semibold text-lg leading-7 text-white">Infomation</p>
                                <ul className="md:mt-8 mt-4 space-y-4">
                                    <li>
                                        <Link to="/services" className="text-[#7B7B7B] hover:text-[#FAFAFA] text-[15px] leading-[22px] duration-300">
                                            Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us" className="text-[#7B7B7B] hover:text-[#FAFAFA] text-[15px] leading-[22px] duration-300">
                                            About
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-conditions" className="text-[#7B7B7B] hover:text-[#FAFAFA] text-[15px] leading-[22px] duration-300">
                                            Terms & Conditions
                                        </Link>
                                    </li>
                                    <li style={{display: 'flex'}}>
                                        <a style={{  paddingRight: '5px'}} href="https://www.facebook.com/ApriZaldotCom/" target="_blank" rel="noreferrer">
                                            <img src="/images/fb.svg" alt="Facebook" />
                                        </a>
                                        <a style={{  paddingRight: '5px', marginTop: '-3px'}} href="https://www.instagram.com/aprizalweb" target="_blank" rel="noreferrer">
                                            <i className='fa fa-instagram'></i>
                                        </a>
                                        <a style={{  paddingRight: '5px', marginTop: '-3px'}} href="https://twitter.com/apriZaldotCOm" target="_blank" rel="noreferrer">
                                            <i className='fa fa-twitter'></i>
                                        </a>
                                        <a style={{  paddingRight: '5px', marginTop: '-3px'}} href="https://www.linkedin.com/in/apri-zal-1a372828a" target="_blank" rel="noreferrer">
                                            <i className='fa fa-linkedin'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="text-white">
                                <p className="font-semibold text-lg leading-7 text-white">Find Us</p>
                                <p className="md:mt-8 mt-4 text-[#7B7B7B] text-[15px] leading-[22px]">16192 Coastal Highway, Lewes, Delaware 19957, County of Sussex</p>
                                <p className="mt-6 text-[#7B7B7B] text-[15px] leading-[22px]">+1 4096 552 653</p>
                                <p className="text-[15px] leading-[22px] text-[#7B7B7B]">info@aprizal.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#3D3D3D] h-px w-full block"></div>
                <div className="grid grid-cols-1">
                    <div className="py-8">
                        <p className="text-[#7B7B7B] text-[15px] leading-7">&copy; 2023 Copyright <Link to="https://aprizal.com" target="_blank" className="text-[#FAFAFA] font-medium">Aprizal.</Link></p>
                    </div>
                </div>
            </div>
        </footer>
    ) 
}

export default Footer